<template>
    <v-app>
        <div>
            <Header/>
        </div>
        <h2 class="main_title"><strong>PEĆNIK</strong> d.o.o.</h2>
        <main class="main_part">
            <div class="frame indent-bot">
                <iframe marginheight="0" marginwidth="0" scrolling="no" src="//maps.google.com/maps?q=45.77334343,15.77794432&amp;z=14&amp;output=embed" width="100%" height="450" frameborder="0"></iframe>
            </div>
            <div id="header">
                <h4>KONTAKT</h4>
            </div>
            <div id="f-container">
                <dl>
                    <div id="first">
                        <dd>Adresa: Srebrnjak 6 C, 10431 Sveta Nedelja</dd>
                        <dd>E-mail: <a class="link" href="mailto:pecnik@pecnik.hr" target="_blank">pecnik@pecnik.hr</a></dd>
                    </div>
                    <div id="second">
                        <dd>Tel: +38513388057</dd>
                        <dd>Mob: +385913388057, +385913388060</dd>
                        <dd>FAX: +38513388057</dd>
                    </div>   
                </dl>
            </div>
            <div id="header" style="padding-top: 5rem">
                <h4>PIŠITE NAM</h4>
            </div>             
            <div class="form_container">
                <form @submit.prevent="sendEmail">
                    <div style="width:60%">
                        <label>Vaše ime</label>
                        <input 
                            type="text" 
                            v-model="name"
                            name="name"
                        >
                        <label>Vaša e-mail adresa</label>
                        <input 
                            type="email" 
                            v-model="email"
                            name="email"
                            >
                    </div>
                <label>Vaša poruka</label>
                <textarea 
                    name="message"
                    v-model="message"
                    cols="30" rows="8"
                    >
                </textarea>
                
                <input type="submit" value="Pošalji">
                </form>
            </div>
        </main>
        <div>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
    import Header from '@/views/Header';
    import Footer from '@/views/Footer';
    import emailjs from 'emailjs-com';
    
    export default {
        name: 'ContactUs',

        components: {
            Header,
            Footer
        },

        data: () => {
            return {
                name: '',
                email: '',
                message: ''
            }
        },
        created () {
        },
        methods: {
            sendEmail(e) {
                try {
                    if (this.name != '' || this.email != '' || this.message != '') {
                        emailjs.sendForm('service_6grr82b', 'template_5tsxjkf', e.target, 'user_FSrXgHnF42m23vwXfgF5g', {
                        name: this.name,
                        email: this.email,
                        message: this.message
                        })
                        console.log('it works!!!')
                    }
                } catch (error) {
                    console.log({error})
                }
                // Reset form field
                this.name = ''
                this.email = ''
                this.message = ''
            },
        }
    }
</script>

<style>
.main_part {
    padding-top: 4rem;
    width: 55%;
    margin: auto;
}
.main_title {
    margin-left: 20rem;
}
h2 {
    font-size: 50px;
    line-height: 1.2em;
    color: #1f232c;
    letter-spacing: -1px;
    text-align: left;
    margin-left: 5rem;
}
h2 strong {
    color: #4199e1;
    text-transform: uppercase;
}
.indent-bot {
    margin-bottom: 20px;
}
.frame {
    padding: 0px;
}
label {
  float: left;
  color: rgb(106, 113, 116);
}
input[type=text], [type=email], textarea {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  background-color: #f2f2f2;
  box-shadow: 0.3px 0.6px #888888;
}
input[type=submit] {
  background-color: #f2f2f2;
  color: rgb(89, 92, 94);
  padding: 12px 35px;
  border: 1px solid rgb(206, 201, 201);
  cursor: pointer;
}
input[type=submit]:hover {
  background-color: #f2f2f2b6;
}
textarea:focus, input:focus{
    outline: none;
}
.form_container {
  display: block;
  margin: auto;
  text-align: left;
  background-color: white;
  width: 90%;
  margin-top: 20px;
}

#header {
    width: 100%;
    margin: auto;
    text-align: left;
    padding-left: 1rem;
}

#header h4 {
    text-align: left;
    margin-top: 2rem;
    font-size: 25px;
    color: black;
}

#f-container {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    text-align: left;
}
#first {
    width: 60%;
    float: left;
}
#second {
    width: 40%;
    float: left;
}

@media only screen and (max-width: 1200px) {
    [class*="main_title"] {
        margin-left: 8rem;
    }
    [class*="main_part"] {
        width: 80%;
        padding-top: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    [class*="main_title"] {
        margin-left: 2rem;
    }
    [class*="main_part"] {
        width: 95%;
    }
    #first {
        width: 100%;
        float: left;
    }
    #second {
        width: 100%;
        margin-bottom: 2rem;
    }
    .frame iframe{
        height: 250px;
        width: 90%;
    }
    h2 {
        font-size: 35px;
    }
    #f-container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .form_container{
        padding-left: 10px;
        padding-right: 10px;
    }
    input[type=text], [type=email], textarea {
        padding: 10px;
        margin-top: 6px;
        margin-bottom: 10px;
    }
    input[type=submit] {
        padding: 8px 30px;
    }
}
</style>
